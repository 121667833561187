import React, { Component } from 'react'

export default class AboutMe extends Component {
  render() {
    return (
      <div className='about'>
        <h1>• Немного обо мне:</h1>
        <div className='aboutText'><h2>Привет, я Funplaer!</h2>  Моё настоящее имя Андрей. Мне 15 полных годиков.<br/> Родился я в городе Ставрополь 5 февраля 2009 года, но почти всю жизнь живу в прекрассном городе Краснодар. Возможно вы знаете меня как FunPlaer_AM (youtube) или Андрей бог чая (telegram). <br/> Я являюсь бэкенд-javascript-разработчиком, так же знаю html-css. Раньше занимался разработкой игр на c# (Unity), но сейчас забросил это дело. Так же знаком с telegram-api, так что могу разрабатывать телеграм-ботов и интегрировать их на сайты. Немного разбираюсь в adobe premiere pro и after effects. <br/> В жизни довольно позитивный человек. Мой рост ~176см. У меня темные черные волосы. Но это вам должно быть не важно. <br/> Меньше чем три </div>
        
      </div>
    )
  }
}
