import React, { Component } from 'react'

export default class Furry extends Component {
  render() {
    return (
      <div>
        
        
        <video src='videos/animation.gif.mp4' autoPlay='true' loop className='furi'></video>
        <div>Привет, ты попал на секретную страницу с фурри </div>
      </div>
    )
  }
}
