import React from "react"
import Home from "./pages/Home";
import None from "./pages/None"
import K4aks from "./pages/rofl/K4aks";
import Midori from "./pages/rofl/Midori"
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Nohelo from "./pages/nohelo/Nohelo";
import Furry from "./pages/rofl/Furry";




class App extends React.Component {
  
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route index element = {<Home />} />
            <Route path='/' element={<Home />} />
            <Route path='*' element={<None />} />
            <Route path='/ko4aks' element={<K4aks />} />
            <Route path='/midori' element={<Midori />} />
            {/* <Route path='/nohelo' element={<Nohelo />} /> */}
            <Route path='/furiii' element={<Furry />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
