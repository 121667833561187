import React, { Component } from 'react'
import AboutMe from "../components/AboutMe"
import Header from "../components/Header"
import Links from "../components/Links"
import Footer from "../components/Footer";


export default class Home extends Component {
  render() {
    return (
      <div>

<div className="App">
        <div className="wrapper">
          <Header />
          <div className="content">
            <AboutMe />
            <Links />
          </div>
          <Footer />
          
        </div>
      </div>
      </div>
    )
  }
}
