import React, { Component } from 'react'


export default class Links extends Component {
  render() {
    return (
      <div>
        <h1>• Некоторые ссылки:</h1>
        <main>
          <div className='item'>          
            <img src='images/tg.jpg' alt=''></img>
            <a href='https://t.me/littlebigcoder'>Telegram</a>
          </div>   

          <div className='item'>          
            <img src='images/ut.jpg' alt=''></img>
            <a href='https://www.youtube.com/@funner_fun'>YouTube</a>
          </div>      

          <div className='item'>          
            <img src='images/twitch.jpg' alt=''></img>
            <a href='https://www.twitch.tv/funplaer_am'>Twitch</a>
          </div>   

          <div className='item'>          
            <img src='images/da.png' alt=''></img>
            <a href='https://www.donationalerts.com/r/funplaer'>DonationAlerts</a>
          </div>      
          
          <div className='item'>          
            <img src='images/al.ico' alt=''></img>
            <a href='https://www.aniluck.ru/'>AniLuck</a>
          </div>
      </main>
      </div>
      
    )
  }
}
